// import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState, useCallback } from 'react';
import Clock from 'react-live-clock';
import { useTheme } from './Theme/Themecontext';

const CardOne = ({ reloadCounter }) => {
  
  const { styles } = useTheme();
  const { component } = styles
 
  const baseUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEV
    : process.env.REACT_APP_BASE_URL_PROD;


  const [userclkInfo, setUserclkInfo] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  // const token = localStorage.getItem("token");
  // const userInfo = jwtDecode(token);
  // console.log("cardone", userInfo);

  const getLocation = useCallback((lat, lon) => {
    const apiUrl = isChecked
      ? `${baseUrl}/punch/out`
      : `${baseUrl}/punch/in`;
    const bodyData = {
      lat: lat,
      lon: lon,
    };

    try {
      const token = localStorage.getItem("token");
      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(bodyData),
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        fetchUserclkInfo();
      })
      .catch(error => {
        console.error('Error:', error);
      });
    } catch (error) {
      console.error('Error:', error);
    }
  }, [isChecked]);

  const fetchUserclkInfo = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${baseUrl}/punch/details`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setIsChecked(data.result.isPunch);
        setUserclkInfo(data.result);
      } 
      else {
        throw new Error("Failed to fetch clock details");
      }
    } catch (error) {
      console.error("Error fetching clock details:", error);
    }
  }, []);

  const formatTime = (timeString) => {
    if (!timeString) return "00:00";
    const time = new Date(timeString);
    let hours = time.getHours();
    let minutes = time.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const formattedTime = hours + ':' + minutes + ' ' + ampm;
    return formattedTime;
  };

  const handleCheckboxChange = async () => {
    // console.log("Before toggle:", isChecked);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          getLocation(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            alert("Please turn on browser location settings to clock in & clock out.");
            getLocation(0.0, 0.0); 
          } else {
            console.error('Error getting location:', error);
            alert('Error getting location. Please ensure location services are enabled.');
            getLocation(0.0, 0.0); 
          }
        }
      );
    } else {
      alert('Geolocation is not supported by your browser.');
      getLocation(0.0, 0.0); 
    }
  };


  // const handleCheckboxChange = async () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         // if (isChecked !== (position.coords.latitude !== 0.0 && position.coords.longitude !== 0.0)) {

  //         if (isChecked) {
  //           getLocation(position.coords.latitude, position.coords.longitude);
  //         }
  //       },
  //       (error) => {
  //         if (error.code === error.PERMISSION_DENIED) {
  //           alert("Please turn on browser location settings to clock in & clock out.");
  //         } else {
  //           console.error('Error getting location:', error);
  //           alert('Error getting location. Please ensure location services are enabled.');
  //         }
  //         if (isChecked) {
  //           getLocation(0.0, 0.0);
  //         }
  //       }
  //     );
  //   } else {
  //     alert('Geolocation is not supported by your browser.');
  //     if (isChecked) {
  //       getLocation(0.0, 0.0);
  //     }
  //   }
  // };
  

  useEffect(() => {
    fetchUserclkInfo();
  }, [fetchUserclkInfo ]);


  return (
    <>
      <div 
      // style={!isSystemLight ? component : systemcomponent } 
        style={component}
        className={`p-2 md:min-w-[350px] min-w-[290px] w-full min-h-[175px] lg:w-[324px] rounded-[10px]`} >
        <div className="p-2 w-full flex flex-1 justify-between">
          <p className=" text-[14px]">{userclkInfo.createDate}</p>
          <Clock
            className="  !text-[14px]"
            format={'hh:mm:ss A'}
            style={{ fontSize: '12px' }}
            ticking={true} />
        </div>
       

        <div className="flex justify-center items-center">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange} 
            id="toggle"
            className="sr-only peer"
          />
          <label htmlFor="toggle" className="relative">
            <div className="border-[#787878] border-[.5px] cursor-pointer h-[52px] w-[160px] bg-[#ffff] rounded-full flex items-center px-1">
              <div
                className={`h-[50px] w-[50px] bg-[#fff] cursor-pointer absolute rounded-full transition-all duration-500 grid place-items-center ${isChecked ? 'left-[110px] top-[1.5px] !bg-[#1E3354]' : 'left-[1px] !bg-[#6f7e95]'
                  }`}
              >
                {isChecked ? (
                  <svg xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 -960 960 960" width="22px" fill="#e8eaed"><path d="m798-270-60-60q11-27 16.5-52.5T760-436q0-116-82-200t-198-84q-28 0-54 4.5T374-700l-60-60q38-20 79.5-30t86.5-10q74 0 139.5 28T734-694.5Q783-645 811.5-579T840-436q0 45-11 86.5T798-270Zm52-370L680-810l56-56 170 170-56 56ZM820-24 694-150q-45 33-99.5 51.5T480-80q-74 0-139.5-28T226-184q-49-48-77.5-113T120-436q0-62 18.5-116.5T192-652l-34-34-48 48-56-56 48-48-74-74 56-56L876-80l-56 56ZM480-159q42 0 82-13t74-36L248-594q-23 35-35.5 75.5T200-436q0 116 82 196.5T480-159Zm-38-242Zm114-114Z" /></svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ffff"><path d="M480-80q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T120-440q0-75 28.5-140.5t77-114q48.5-48.5 114-77T480-800q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-440q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480-80Zm0-360Zm112 168 56-56-128-128v-184h-80v216l152 152ZM224-866l56 56-170 170-56-56 170-170Zm512 0 170 170-56 56-170-170 56-56ZM480-160q117 0 198.5-81.5T760-440q0-117-81.5-198.5T480-720q-117 0-198.5 81.5T200-440q0 117 81.5 198.5T480-160Z" /></svg>
                )}
              </div>
            </div>
            <span className={`absolute w-[150px] left-[130px] top-1/2   cursor-pointer   transform -translate-x-1/2 -translate-y-1/2 ${isChecked ? 'text-[#1E3354] w-[180px] ml-[-10px]' : 'text-[#1E3354] ml-[10px] '}`}>
              {isChecked ? 'Clock Out' : 'Clock In'}
            </span>
          </label>
        </div>




        <div className="flex flex-1 justify-between p-2 mt-2">
          <div className="text-center">
            <p className="text-[14px]  ">Clock In</p>
            <p className="text-[14px]  ">{formatTime(userclkInfo.punchIn)}</p>
          </div>
          <div className="text-center">
            <p className="text-[14px]  ">Clock Out</p>
            <p className="text-[14px]  ">{formatTime(userclkInfo.punchOut)}</p>
          </div>
          <div className="text-center">
            <p className="text-[14px]   ">Location</p>
            <p className="text-[14px]  ">{isChecked ? userclkInfo.locationIn : userclkInfo.locationOut}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardOne;













  // const getLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       async (position) => {
  //         const lat = position.coords.latitude;
  //         const lon = position.coords.longitude;
  //         const apiUrl = isChecked
  //           ? 'http://20.174.26.6:3000/v1/punch/out'
  //           : 'http://20.174.26.6:3000/v1/punch/in';
  //         const bodyData = {
  //           lat: lat,
  //           lon: lon,
  //         };

  //         try {
  //           const token = localStorage.getItem("token");
  //           const userInfo = jwtDecode(token);
  //           console.log("jwttoken", userInfo)

  //           const response = await fetch(apiUrl, {
  //             method: 'POST',
  //             headers: {
  //               'Content-Type': 'application/json',
  //               'Authorization': `Bearer ${token}`

  //             },
  //             body: JSON.stringify(bodyData),
  //           });
  //           const data = await response.json();
  //           // window.location.reload();

  //           console.log(data);
  //         } catch (error) {
  //           console.error('Error:', error);
  //         }
  //       },
  //       (error) => {
  //         console.error('Error getting location:', error);
  //       }
  //     );
  //   } else {
  //     console.error('Geolocation is not supported by this browser.');
  //   }
  // };













  

  // const handleCheckboxChange = async () => {
  //   console.log("Before toggle:", isChecked);
  //   // setIsChecked(prevChecked => !prevChecked);
  //   console.log("After toggle:", isChecked);
  //   getLocation();
  //   fetchUserclkInfo();
  // };


  // const handleCheckboxChange = async () => {
  //   console.log("Before toggle:", isChecked);
  //   // setIsChecked(prevChecked => !prevChecked);
  //   console.log("After toggle:", isChecked);
    
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         getLocation();
  //         fetchUserclkInfo();
  //       },
  //       (error) => {
  //         if (error.code === error.PERMISSION_DENIED) {
  //           alert("Please turn on browser location settings to clock in & clock out.");
  //         } else {
  //           console.error('Error getting location:', error);
  //           alert('Error getting location. Please ensure location services are enabled.');
  //         }
  //       }
  //     );
  //   } else {
  //     alert('Geolocation is not supported by your browser.');
  //   }
  // };
  