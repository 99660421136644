import "./App.css";
import { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import SignIn from "./pages/Authentication/SignIn";
import routes from "./routes";
import DefaultLayout from "./layout/DefaultLayout";
import Loader from "./common/Loader";
import Dashboard from "./pages/Dashboard/Dashboard";
import SignUp from "./pages/Authentication/SignUp";
import { ThemeProvider } from "./components/Theme/Themecontext";


function App() {
  const [loading, setLoading] = useState(true);
  const isUserLoggedIn = window.localStorage.getItem("token") !== null;
  console.log(isUserLoggedIn);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);



  return (
    <ThemeProvider>
      {loading ? (
        <Loader />
      ) : (
      <Router>
        <Routes>
          <Route path="/login" element={<SignIn />} />
          <Route path="/password" element={<SignUp />} />

          {isUserLoggedIn ? (

            <Route element={<DefaultLayout />}>
              
              <Route path="/" element={<Navigate to="/home" replace />} />
              <Route path="/home" element={<Dashboard />} />

              {routes.map(({ path, component: Component }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <Suspense fallback={<Loader />}>
                      <Component />
                    </Suspense>
                  }
                ></Route>
              ))}
            </Route>
          ) : (
            <Route path="/*" element={<Navigate to="/login" replace />} />
          )}
        </Routes>
      </Router>)}
    </ThemeProvider>  
  );
}

export default App;
