import React from 'react';

const CeoCircularProgress = ({ total, values, sum }) => {
  const radius = 100;
  const stroke = 20;

  const circumference = 2 * Math.PI * radius;
  const normalizedRadius = radius - stroke / 2;
  const calculateSegments = (values, total) => {
    let segments = [];
    let cumulativeLength = 0;

    values.forEach((value, index) => {
      if (value !== 0) {
        const segmentLength = (value / total) * circumference;
        const angle = (cumulativeLength / circumference) * 360 + 90;
        const textAngle = angle + (segmentLength / circumference) * 580 / 2; 

        const textX = radius + normalizedRadius * Math.cos((textAngle * Math.PI) / 180);
        const textY = radius + normalizedRadius * Math.sin((textAngle * Math.PI) / 180);

        segments.push({
          length: segmentLength,
          color: getColor(index),
          offset: cumulativeLength,
          value: value,
          textX: textX,
          textY: textY,
          angle: angle,
        });

        cumulativeLength += segmentLength;
      }
    });

    return segments;
  };

  const getColor = (index) => {
    const colors = ['#008000', '#4ADE80', '#808080']; 
    return colors[index];
  };

  const segments = calculateSegments(values, total);

  return (
    <div className="relative w-64 h-64 my-6">
      <svg className="inset-0 w-full h-full absolute left-7 ">
        {segments.map((segment, index) => (
          <g key={index}>
            <circle
              cx={radius}
              cy={radius}
              r={normalizedRadius}
              stroke={segment.color}
              strokeWidth={stroke}
              fill="none"
              strokeDasharray={`${segment.length} ${circumference}`}
              strokeDashoffset={-segment.offset}
              transform={`rotate(90 ${radius} ${radius})`}
            />
            <text
              x={segment.textX}
              y={segment.textY}
              textAnchor="middle"
              className="text-xs font-semibold"
              fill="#ffffff"
              // transform={`rotate(2000${segment.angle} ${segment.textX} ${segment.textY})`}
            >
              {segment.value}
            </text>
          </g>
        ))}
      </svg>
      <div className="absolute top-20 flex flex-col text-center items-center left-0 right-0 ">
        <div className="text-2xl font-semibold" style={{ marginLeft: '5px' }}>
          {sum}/{total}
        </div>
        <div className="text-[12px] font-semibold">
          Attendance Status
        </div>
      </div>

    </div>
  );
};

export default CeoCircularProgress;
