import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

function SignIn() {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get("navUrl");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    const lowerCaseValue = name === "email" ? value.toLowerCase() : value;

    setFormData({ ...formData, [name]: lowerCaseValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      const { email, password } = formData;
      const ENC_KEY = "mandoobee";
      var encpassword = CryptoJS.AES.encrypt(password, ENC_KEY).toString();
      console.log("Encrypted Password:", encpassword);
      const response = await axios.post(`${baseUrl}/login`, {
        email,
        password: encpassword,
      });

      if (response.data.isSuccess) {
        localStorage.setItem("token", response.data.result.token);

        console.log("loginscxcxccc", response.data.result.token);

        window.location = "/home";
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status >= 400 &&
        err.response.status <= 500
      ) {
        setError(err.response.data.message);
      } else {
        setError("Internal Server Error");
      }
    }
  };

  useEffect(() => {
    if (query) {
      handleSubmit();
    }
  }, [query]);

  return (
    <div className="bg-[#004b8d] grid grid-cols-1 lg:grid-cols-2  h-screen w-full">
      <div className="flex w-full items-center justify-center">
        <img
          className="h-[100px] w-[100px] "
          src="./assets/Mandoobee_Logo.png"
          alt=""
          data-csiid="12"
          data-atf="1"
        />
        <div className="px-3">
          <p className="text-[32px] font-semibold text-white ">Mandoobee</p>
          <p className="text-[15px] lg:text-[16px] font-semibold text-[#ffffff]">
            Smart Office Application
          </p>
        </div>
      </div>
      <div className="flex w-full lg:items-center justify-center">
        <div className="px-5 lg:px-10 ">
          <form onSubmit={handleSubmit}>
            <input
              className="border rounded-lg w-full py-2 px-3 text-gray-700 mb-3"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
            />
            <input
              className="border mt-4 rounded-lg w-full py-2 px-3 text-gray-700 mb-3"
              // id="password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password"
            />
            <button
              type="onsubmit"
              className="bg-white mt-4 font-serif font-bold hover:bg-[#5c8ab9] hover:text-[#fff] rounded-lg w-full py-2 px-3 text-gray-800 mb-3"
            >
              Login
            </button>
          </form>
          {error && (
            <p className="error-message text-red-500 justify-center flex">
              {error}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default SignIn;
