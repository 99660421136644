// import React, { useEffect, useState } from "react";
// import { Link, useLocation } from "react-router-dom";

// function CeoInbox(isRefresh) {
//   // console.log('refresh from inbox',isRefresh)

//   const baseUrl =
//     process.env.NODE_ENV === "development"
//       ? process.env.REACT_APP_BASE_URL_DEV
//       : process.env.REACT_APP_BASE_URL_PROD;

//   const token = localStorage.getItem("token");

//   const [options, setOptions] = useState([]);
//   const [momCount, setMomCount] = useState(0);
//   const [docCount, setDocCount] = useState(0);
//   const [approvalCount, setApprovalCount] = useState(0);

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchDocuments = async () => {
//       try {
//         const [momResponse, docResponse, approvalResponse] = await Promise.all([
//           fetch(`${baseUrl}/mom/getWorkFlow?status=Inbox`, {
//             method: "GET",
//             headers: {
//               Authorization: "Bearer " + token,
//               "Content-Type": "application/json",
//             },
//           }),
//           fetch(`${baseUrl}/document/getDoc?status=Inbox`, {
//             method: "GET",
//             headers: {
//               Authorization: "Bearer " + token,
//               "Content-Type": "application/json",
//             },
//           }),
//           fetch(`${baseUrl}/smartservice/getAll`, {
//             method: "GET",
//             headers: {
//               Authorization: "Bearer " + token,
//               "Content-Type": "application/json",
//             },
//           }),
//         ]);

//         if (!momResponse.ok || !docResponse.ok || !approvalResponse.ok) {
//           throw new Error("Failed to fetch documents");
//         }

//         const [momData, docData, approvalData] = await Promise.all([
//           momResponse.json(),
//           docResponse.json(),
//           approvalResponse.json(),
//         ]);

//         const combinedData = [
//           ...momData.result,
//           ...docData.result,
//           ...approvalData.result,
//         ];
//         setOptions(combinedData, "result");

//         setMomCount(momData.result.length);
//         setDocCount(docData.result.length);
//         const pendingApprovalCount = approvalData.result.filter(
//           (item) => item.status === "Pending"
//         ).length;
//         setApprovalCount(pendingApprovalCount);
//         // console.log(combinedData, "combined view");

//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching documents:", error);
//         setError("Failed to fetch documents");
//         setLoading(false);
//       }
//     };

//     fetchDocuments();
//   }, [token, baseUrl, isRefresh]);

//   const handleRefresh = () => {
//     window.location.reload();
//   };

//   return (
//     <div className="p-5 md:border-hidden md:p-0 md:bg-transparent bg-[#ffffff] border-[1px] border-opacity-[50%] border-[#787878] rounded-[10px] h-full">
//       <div className="mb-2 md:hidden flex justify-between">
//         <p className="text-[#1E3354] text-[16px] font-[600]">Inbox</p>

//         <button onClick={handleRefresh}>
//           <span class="material-symbols-outlined">refresh</span>
//         </button>
//       </div>

//       <div className="">
//         <div className="border-[1px] cursor-pointer border-[#787878] border-opacity-[50%] rounded-2xl md:border-[0px]">
//           <div className="p-3 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-5 md:space-y-0">
//             <Link to={`/home/viewmom?status=Inbox&title=Inbox`}>
//               <div className="w-full h-[100px] border rounded-xl border-[#787878] p-2 relative bg-[#ffffff]">
//                 <div className="flex-1 flex justify-between items-center">
//                   <h3 className="text-[#3D3D3D] font-semibold text-[14px]">
//                     Mom
//                   </h3>
//                 </div>
//                 <div className="absolute bottom-2 left-2 flex flex-row gap-1">
//                   <p className="text-[14px] font-medium text-[#1E3354]">
//                     View More
//                   </p>
//                   <span className="material-symbols-outlined !text-[#1E3354] !text-[14px] !font-bold mt-[5px]">
//                     arrow_forward
//                   </span>
//                 </div>
//                 <div className="absolute -top-[10px] -right-[4px] rounded-full bg-[#f24040] text-white w-[30px] h-[30px] flex items-center justify-center">
//                   <h1 className="text-[14px] font-semibold">{momCount}</h1>
//                 </div>
//               </div>
//             </Link>
//             <Link
//               to={`/home/smartdocument/viewdocument?status=Inbox&title=Inbox`}
//             >
//               <div className="w-full  h-[100px] border rounded-xl border-[#787878] p-2 relative bg-[#ffffff]">
//                 <div className="flex-1 flex justify-between items-center">
//                   <h3 className="text-[#3D3D3D] font-semibold text-[14px]">
//                     Smart Document
//                   </h3>
//                 </div>

//                 <div className="absolute bottom-2 left-2 flex flex-row gap-1">
//                   <p className="text-[14px] font-medium text-[#1E3354]">
//                     View More
//                   </p>
//                   <span class="material-symbols-outlined !text-[#1E3354] !text-[14px] !font-bold mt-[5px]">
//                     arrow_forward
//                   </span>
//                 </div>
//                 <div className="absolute -top-[10px] -right-[4px] rounded-full bg-[#f24040] text-white w-[30px] h-[30px] flex items-center justify-center">
//                   <h1 className="text-[14px] font-semibold">{docCount}</h1>
//                 </div>
//               </div>
//             </Link>
//             <Link to="/home/smartserviceDASHBOARD">
//               <div className="w-full  h-[100px] border rounded-xl border-[#787878] p-2 relative bg-[#ffffff]">
//                 <div className="flex-1 flex justify-between items-center ">
//                   <h3 className="text-[#3D3D3D] font-semibold text-[14px]">
//                     Smart Service
//                   </h3>
//                 </div>
//                 <div className="absolute bottom-2 left-2 flex flex-row gap-1">
//                   <p className="text-[14px] font-medium text-[#1E3354]">
//                     View More
//                   </p>
//                   <span className="material-symbols-outlined !text-[#1E3354] !text-[14px] !font-bold mt-[5px]">
//                     arrow_forward
//                   </span>
//                 </div>
//                 <div className="absolute -top-[10px] -right-[4px] rounded-full bg-[#f24040] text-white w-[30px] h-[30px] flex items-center justify-center">
//                   <h1 className="text-[14px] font-semibold">{approvalCount}</h1>
//                 </div>
//               </div>
//             </Link>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CeoInbox;

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "./Theme/Themecontext";


function CeoInbox(isRefresh) {
  // console.log('refresh from inbox',isRefresh)

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const token = localStorage.getItem("token");

  const { styles } = useTheme()
  const { component } = styles
  const [options, setOptions] = useState([]);
  const [momCount, setMomCount] = useState(0);
  const [docCount, setDocCount] = useState(0);
  const [approvalCount, setApprovalCount] = useState(0);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const [momResponse, docResponse, approvalResponse] = await Promise.all([
          fetch(`${baseUrl}/mom/getWorkFlow?status=Inbox`, {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }),
          fetch(`${baseUrl}/document/getDoc?status=Inbox`, {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }),
          fetch(`${baseUrl}/smartservice/getAll`, {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }),
        ]);

        if (!momResponse.ok || !docResponse.ok || !approvalResponse.ok) {
          throw new Error("Failed to fetch documents");
        }

        const [momData, docData, approvalData] = await Promise.all([
          momResponse.json(),
          docResponse.json(),
          approvalResponse.json(),
        ]);

        const combinedData = [
          ...momData.result,
          ...docData.result,
          ...approvalData.result,
        ];
        setOptions(combinedData, "result");

        setMomCount(momData.result.length);
        setDocCount(docData.result.length);
        const pendingApprovalCount = approvalData.result.filter(
          (item) => item.status === "Pending"
        ).length;
        setApprovalCount(pendingApprovalCount);
        // console.log(combinedData, "combined view");

        setLoading(false);
      } catch (error) {
        console.error("Error fetching documents:", error);
        setError("Failed to fetch documents");
        setLoading(false);
      }
    };

    fetchDocuments();
  }, [token, baseUrl, isRefresh]);

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="p-5 md:border-hidden md:p-0 md:bg-transparent bg-[#ffffff] border-[1px] border-opacity-[50%] border-[#787878] rounded-[10px] h-full">
      <div className="mb-2 md:hidden flex justify-between">
        <p className="text-[#1E3354] text-[16px] font-[600]">Inbox</p>

        <button onClick={handleRefresh}>
          <span class="material-symbols-outlined">refresh</span>
        </button>
      </div>
      {/* <div className="absolute bottom-2 left-2 flex flex-row gap-1">
                  <p className="text-[14px] font-medium text-[#1E3354]">
                    View More
                  </p>
                  <span className="material-symbols-outlined !text-[#1E3354] !text-[14px] !font-bold mt-[5px]">
                    arrow_forward
                  </span>
                </div> */}
      <div className=" ">
        <div className="border-[1px] cursor-pointer border-[#787878] border-opacity-[50%] rounded-2xl md:border-[0px]">
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-3 gap-5 md:space-y-0">
            <Link to={`/home/viewmom?status=Inbox&title=Inbox`}>
              <div style={component}  className="w-[150px] h-[50px] border rounded-xl border-[#787878] p-2 relative ">
                <div className="flex-1 flex justify-center items-center">
                  <h3 className=" font-semibold text-[14px] text-center">
                    Mom
                  </h3>
                </div>
                {/* <div className="absolute bottom-2 left-2 flex flex-row gap-1">
                  <p className="text-[14px] font-medium text-[#1E3354]">
                    View More
                  </p>
                  <span className="material-symbols-outlined !text-[#1E3354] !text-[14px] !font-bold mt-[5px]">
                    arrow_forward
                  </span>
                </div> */}
                <div className="absolute -top-[10px] -right-[4px] rounded-[10px] bg-[#EA001B] text-white w-[25px] h-[20px] flex items-center justify-center">
                  <h1 className="text-[14px] font-semibold">{momCount}</h1>
                </div>
              </div>
            </Link>
            <Link
              to={`/home/smartdocument/viewdocument?status=Inbox&title=Inbox`}
            >
              <div style={component} className="w-[150px]  h-[50px] border rounded-xl  p-2 relative ">
                <div className="flex-1 flex justify-center items-center">
                  <h3 className=" font-semibold text-[14px] text-center">
                    Smart Document
                  </h3>
                </div>

                {/* <div className="absolute bottom-2 left-2 flex flex-row gap-1">
                  <p className="text-[14px] font-medium text-[#1E3354]">
                    View More
                  </p>
                  <span class="material-symbols-outlined !text-[#1E3354] !text-[14px] !font-bold mt-[5px]">
                    arrow_forward
                  </span>
                </div> */}
                <div className="absolute -top-[10px] -right-[4px] rounded-[10px] bg-[#EA001B] text-white w-[25px] h-[20px] flex items-center justify-center">
                <h1 className="text-[14px] font-semibold">{docCount}</h1>
                </div>
              </div>
            </Link>
            <Link to="/home/smartserviceDASHBOARD">
              <div style={component} className="w-[150px]  h-[50px] border rounded-xl p-2 relative">
                <div className="flex-1 flex justify-center items-center ">
                  <h3 className=" font-semibold text-[14px] text-center">
                    Smart Service
                  </h3>
                </div>
                {/* <div className="absolute bottom-2 left-2 flex flex-row gap-1">
                  <p className="text-[14px] font-medium text-[#1E3354]">
                    View More
                  </p>
                  <span className="material-symbols-outlined !text-[#1E3354] !text-[14px] !font-bold mt-[5px]">
                    arrow_forward
                  </span>
                </div> */}
                <div className="absolute -top-[10px] -right-[4px] rounded-[10px] bg-[#EA001B] text-white w-[25px] h-[20px] flex items-center justify-center">
                <h1 className="text-[14px] font-semibold">{approvalCount}</h1>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CeoInbox;
