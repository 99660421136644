import React from 'react'

function SignUp() {
  return (
    <div className="bg-[#004b8d] flex h-screen w-full">
    <div className="flex w-full items-center justify-center">
      <img
        className="h-[100px] w-[100px] "
        src="../../images/logo/logo.png"
        alt=""
        data-csiid="12"
        data-atf="1"
      />
      <div className="px-3">
        <p className="text-[32px] font-semibold text-white ">Mandoobee</p>
        <p className="text-[15px] lg:text-[16px] font-semibold text-[#ffffff]">
          Smart Office Application
        </p>
      </div>
    </div>
    <div className="flex  w-full items-center justify-center">
      <div className="px-5 lg:px-10 mt-10 lg:mt-0 ">
        <input
          className="border rounded-lg w-full py-2 px-3 text-gray-700 mb-3"
          type="email"
          placeholder="Email"
        />
        <input
          className="border mt-4 rounded-lg w-full py-2 px-3 text-gray-700 mb-3"
          id="password"
          type="password"
          placeholder="Password"
        />
         {/* <input
          className="border mt-4 rounded-lg w-full py-2 px-3 text-gray-700 mb-3"
          id="password"
          type="password"
          placeholder="Password"
        /> */}
        <button
          className="bg-white mt-4 hover:bg-[#5c8ab9] hover:text-[#ffffff] rounded-lg w-full py-2 px-3 text-gray-800 mb-3"
          type="button"
        >
          Login
        </button>
      </div>
    </div>
  </div>
  )
}

export default SignUp