

const Loader = () => {

    return (
      <div className="flex h-screen items-center justify-center ">
        <div  className="h-16 w-16 animate-spin rounded-full border-4 border-solid  border-t-transparent"></div>
      </div>
    );
  };
  
  export default Loader;
  