import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import UserOne from '../images/user/user-01.png';
import { jwtDecode } from 'jwt-decode';


const DropdownUser = () => {

    const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;
  
    const [userInfo, setUserInfo] = useState({});
    // console.log("fffhghgff", userInfo);

    useEffect(() => {
        const token = localStorage.getItem("token");

        try {
            fetch(`${baseUrl}/user/userDetails`, {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error("Failed to fetch booking details");
                    }
                })
                .then((data) => {
                    console.log("sc", data.result);
                    setUserInfo(data.result);
                })
                .catch((error) => {
                    console.error("Error fetching booking details:", error);
                });
        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <div className="relative ">
            <Link

                className="flex items-center gap-4"
                to="/home/userprofile"
            >
                <span className=" md:h-12 w-9 md:w-12 h-8 ">
                    <img src={userInfo.image} alt="User" className='rounded-full' />
                </span>
                <span className="hidden text-left lg:block">
                    <span className="block text-sm  ">{userInfo.name}</span>
                    <span className="block text-xs ">{userInfo.position}</span>
                </span>

            </Link>

        </div>
    );
};

export default DropdownUser;
