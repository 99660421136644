import React from 'react';

const ThemeStyles = (theme) => {
  const isSystemDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  const SelectedTheme = theme === 'dark' || (theme === 'system' && isSystemDark);

  // const SelectedTheme = theme === 'dark' || (theme === 'system');
  const placeholderClass = SelectedTheme ? 'placeholder-dark' : 'placeholder-light';


  return {
    component: {
      backgroundColor: SelectedTheme ? '#5E6267' : 'white', 
      color: SelectedTheme ? 'white' : '#787878',
      borderColor: SelectedTheme ? 'white' : '#1E3354',
      borderWidth: '3px',
      borderStyle: 'solid',
    },
    bg:{
      backgroundColor: SelectedTheme ? '#5E6267' : 'white', 
      color: SelectedTheme ? 'white' : '#787878',
     
    },
    bgColor : {
      backgroundColor: SelectedTheme ? '#5E6267' : '#E7ECEF', 
      color: SelectedTheme ? 'white' : '#787878',
    },
    bgTitle : {
      backgroundColor: SelectedTheme ? 'white' : '#1E3354', 
      color: SelectedTheme ? '#1E3354' : 'white',
    },
    input : {
      backgroundColor: SelectedTheme ? '#5E6267' : '#E7ECEF', 
      color: SelectedTheme ? 'white' : '#787878',
      borderColor: SelectedTheme ? 'white' : 'rgba(120, 120, 120, 0.5)',
      borderWidth: '1px',
      borderStyle: 'solid',
    },
    border : {
      borderColor: SelectedTheme ? 'white' : '#1E3354',
      borderWidth: '1px',
      borderStyle: 'solid',
    },
    bluetext :{
      color : SelectedTheme ? 'white' : '#1E3354'
    },
    blacktext :{
      color : SelectedTheme ? 'white' : 'black'
    },
    Svgcolor : {
      fill : SelectedTheme ? 'white' : '#1E3354',
      stroke : SelectedTheme ? 'white' : '#1E3354',
    },
    SvgStroke : {
      stroke : SelectedTheme ? 'white' : '#1E3354',
    },
    sidebarborder : {
      borderColor : SelectedTheme ? "white" :'rgba(120, 120, 120)'
    },
    fgcolor : {
      fgColor : SelectedTheme ? 'black': 'white'
    },
    bgcolor : {
      bgColor : SelectedTheme ? 'white': 'black'
    },
    placeholderClass
  }
};

export default ThemeStyles;
