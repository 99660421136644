import { useState } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../components/Footer';
// import { useTheme } from '../components/Theme/Themecontext';


const DefaultLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [headerOpen, setHeaderOpen] = useState(false);
  // const {theme} = useTheme()

 


  const location = useLocation();

  const isDashboard = location.pathname === "/home";
  

  return (

    <div className="">
      <div className="flex h-screen overflow-hidden ">
        {/* <!-- Sidebar Start --> */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* <!-- Content  Start --> */}
        <div className="relative  flex flex-1 flex-col overflow-hidden">
          {/* <!-- Header Start --> */}
          <Header className="fixed top-0 left-0 w-full z-50" headerOpen={headerOpen}  setHeaderOpen={setHeaderOpen} />

          {/* <!-- Main  --> */}

          <main className={`overflow-y-auto flex-1  `}>

            <div className={`mx-auto max-w-screen p-4 md:p-5 2xl:p-10`}>

              {<Outlet />}

            </div>
          </main>
          {/* Footer start mobile */}
          {isDashboard &&
            <Footer className="fixed top-0 left-0 w-full " />}
        </div>
      </div>
    </div>
   
  );
};

export default DefaultLayout;
