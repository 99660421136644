import React from "react";
import { Link} from 'react-router-dom';
import routes from "../routes";

function HeaderAllPage({ currentPath }) {

  

  const handleGoBack = () => {
    window.history.back();
  };


  const getBasePath = (path) => {
    const segments = path.split('/');
    if (segments.length > 2) {
      segments.pop();
      return segments.join('/');
    }
    return path;
  };

  const basePath = getBasePath(currentPath);
  const currentRoute = routes.find((route) => route.path === currentPath);

  const routeName = currentRoute ? currentRoute.name : "";

  

  return (
    <>
      {/* mobile */}
      <header className="lg:hidden relative rounded-b-[30px] lg:rounded-[0px] top-0  flex w-full border-b-2 bg-[#1E3354] lg:bg-[#ffffff] drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none">
        <div className="h-[70px] flex w-full items-center justify-between px-4">
          <button  onClick={handleGoBack} className="flex items-center text-white" >
            <svg
              className="w-6 h-6 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </button>
          <div className=" text-white">
            {routeName}{" "}
          </div>
          <div></div>
        </div>
      </header>
    </>
  );
}

export default HeaderAllPage;
