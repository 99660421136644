import React, { useEffect, useState } from "react";
import CardOne from "../../components/CardOne";
import CardFour from "../../components/CardFour";
import CardThree from "../../components/CardThree";
import CardTwo from "../../components/CardTwo";
import SmartAttendance from "../../components/SmartAttendance";
import MeetingCalendar from "../../components/MeetingCalendar";
import RecentTask from "../../components/RecentTask";
import { Link } from "react-router-dom";
import HeaderHome from "../../components/Headerhome";
import QuickCard from "../../components/QuickCard";
import QuickAll from "../../components/QuickAll";
import CeoCard from "../../components/CeoCard";
import CeoInbox from "../../components/CeoInbox";
import CeoOverallTask from "../../components/CeoOverallTask";
import CeoOverallproject from "../../components/CeoOverallProject";
import { jwtDecode } from "jwt-decode";
import { useTheme } from "../../components/Theme/Themecontext";



function Dashboard() {
  const token = localStorage.getItem("token");
  const userInfo = jwtDecode(token);
  // console.log("dasboard", userInfo)

  const { styles,theme,isSystemLight } = useTheme();
  const { component, SvgStroke ,border,bluetext } = styles
  const [reloadCounter, setReloadCounter] = useState(0);

  const RELOAD_INTERVAL = 500;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setReloadCounter((prevCounter) => prevCounter + 1);
    }, RELOAD_INTERVAL);

    return () => clearInterval(intervalId);
  }, []);

  setTimeout(() => {
    setIsRefresh(false);
  }, 5000);

  const [isRefresh, setIsRefresh] = useState(false);
  const handleRefresh = () => {
    setIsRefresh(true);
  };

<style>
  
</style>
  return (
    <>
    
      <div className={`xl:mr-20 `}>
        <div className=" lg:grid lg:grid-cols-2 xl:grid-cols-4 gap-5 items-center ">
          <div className="lg:hidden mb-4">
            <QuickCard />
          </div>

          <CardOne reloadCounter={reloadCounter} />
          {/* <CardTwo />
          <CardThree />
          <CardFour /> */}
        </div>

        <div className="mt-4 ">
          <SmartAttendance reloadCounter={reloadCounter} />
        </div>

       

        <div className="mt-4 ">
          <div className="my-3 hidden md:flex justify-between">
            <p style={bluetext} className="text-[16px] font-[600]">All My Tasks</p>
            <Link to="/home/task">
              <p style={bluetext} className="text-[16px] cursor-pointer ">See More</p>
            </Link>
          </div>
          <RecentTask reloadCounter={reloadCounter} />
        </div>

        <div className="mt-4">
          <div className="my-3 hidden md:flex justify-between">
            <p style={bluetext} className="text-[16px] font-[600]">Meeting For Today </p>
            <Link to="/home/meeting">
              <p style={bluetext} className="text-[16px]  cursor-pointer">See More</p>
            </Link>
          </div>
          <MeetingCalendar reloadCounter={reloadCounter} />
        </div>

        
        {userInfo.role === 0   ? (
          <div className="mt-4 ">
            <div className="my-3 hidden md:flex justify-between">
              <p style={bluetext} className="text-[16px] font-[600]">Employee Overview</p>
              <button onClick={handleRefresh}>
                <span style={bluetext} className="material-symbols-outlined">refresh</span>
              </button>
            </div>
            <CeoCard isRefresh={isRefresh} />
          </div>
        ) : null}

        {/* <div className="mt-4 ">
          <div className="my-3 hidden md:flex justify-between">
            <p className="text-[16px] font-[600]">Inbox</p>
            <button onClick={handleRefresh}>
              <span className="material-symbols-outlined">refresh</span>
            </button>
          </div>
          <CeoInbox isRefresh={isRefresh} />
        </div> */}

        {userInfo.role === 1  ? (
          <div className="mt-4 ">
            <div className="my-3 hidden md:flex justify-between">
              <p style={bluetext} className="text-[16px] font-[600]">OverAll In Progress Task</p>
              <Link to="/home/overallinprogresstask">
                <p style={bluetext} className="">See More</p>
              </Link>
            </div>
            <CeoOverallTask reloadCounter={reloadCounter} />
          </div>
        ) : null}

        {userInfo.role === 1  || userInfo.role === 2  || userInfo.role === 0 ?  (
          <div className="mt-4 ">
            <div className="my-3 hidden md:flex justify-between">
              <p style={bluetext} className="text-[16px] font-[600]">
                Projects
              </p>
              <Link to="/home/overallinprogressproject">
                <p style={bluetext} className="">See More</p>
              </Link>
            </div>
            <CeoOverallproject reloadCounter={reloadCounter} />
          </div>
        ) : null}
      </div>

     
    </>
  );
}

export default Dashboard;
