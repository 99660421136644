import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "./Theme/Themecontext";


function SmartAttendance({ reloadCounter }) {
  const baseUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEV
    : process.env.REACT_APP_BASE_URL_PROD;

  const [userclkInfo, setUserclkInfo] = useState([]);
  const { styles } = useTheme()
  const { component , border , bluetext } = styles

  // console.log("smart attendance", userclkInfo);

  const fetchUserclkInfo = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
      `${baseUrl}/home/attendance`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();

        setUserclkInfo(data.result);
      } else {
        throw new Error("Failed to fetch booking details");
      }
    } catch (error) {
      console.error("Error fetching booking details:", error);
    }
  };

  // formate clock in clockout

  const formatTime = (timeString) => {
    if (!timeString) return "00:00";
    const time = new Date(timeString);
    let hours = time.getHours();
    let minutes = time.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedTime = hours + ":" + minutes + " " + ampm;
    return formattedTime;
  };

  const formatMonthDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "short" });
    // const day = date.getDate();
    return `${month} `;
  };

  const formatDay = (dateString) => {
    const date = new Date(dateString);
    return date.getDate();
  };

  useEffect(() => {
    fetchUserclkInfo();
  }, []);


  return (
    <>
      <div style={window.innerWidth < 768 ? component : {}} className={" md:border-hidden md:p-0 md:bg-transparent p-4 rounded-[10px]"}>
        <div style={bluetext}  className="mb-2 flex justify-between">
          <p className="text-[16px] font-[600]">
            Smart Attendance
          </p>
          <Link to="/home/smartattendance">
            {" "}
            <p className=" text-[16px] cursor-pointer ">
              See More
            </p>
          </Link>
        </div>
        <div className="rounded-[10px] flex  justify-evenly">
          <div className="w-full grid grid-flow-col !md:grid-cols-2 xl:grid-cols-3 gap-4 scroll-container">
            {userclkInfo.slice(0, 3).map((attendance, index) => (
              <div 
              // style={!isSystemLight ? component : systemcomponent }
              style={component}
                key={index}
                className=" justify-evenly border-[1px] flex items-center border-[#787878] border-opacity-[50%] p-1 md:p-3 w-[254px] min-[375px]:w-[307px] min-[425px]:w-[358px] sm:w-[350px]  bg-[#FFFFFF] rounded-[10px]"
              >
                <div className="lg:w-[100px]">
                  <div className="h-[75px] w-[75px] rounded-[10px] drop-shadow-xl px-1">
                    <div className="bg-[#1E3354]  rounded-t-[10px]">
                      <p className="text-[#ffffff] text-[16px] font-[600] text-center">
                        {formatMonthDate(attendance.createDate)}
                      </p>
                    </div>
                    <div className="bg-[#ffffff] h-[50px] rounded-b-[10px]">
                      <p className="text-[#787878] text-[32px] text-center">
                        {formatDay(attendance.createDate)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="border-l h-[80px] w-[130px] lg:w-[130px] border-[#787878] ">
                  <div className="ml-2 h-[70px]">
                    <p className="text-[12px] mt-2 ">Clock In</p>
                    <div className="flex items-center mt-1">
                      <div className="w-[18px] flex justify-center">
                        <svg
                          width="16"
                          height="14"
                          viewBox="0 0 16 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.00039 13.6664C7.16706 13.6664 6.3865 13.5081 5.65872 13.1914C4.93095 12.8747 4.29761 12.447 3.75872 11.9081C3.21984 11.3692 2.79206 10.7359 2.47539 10.0081C2.15872 9.28029 2.00039 8.49974 2.00039 7.66641C2.00039 6.83307 2.15872 6.05252 2.47539 5.32474C2.79206 4.59696 3.21984 3.96363 3.75872 3.42474C4.29761 2.88585 4.93095 2.45807 5.65872 2.14141C6.3865 1.82474 7.16706 1.66641 8.00039 1.66641C8.83372 1.66641 9.61428 1.82474 10.3421 2.14141C11.0698 2.45807 11.7032 2.88585 12.2421 3.42474C12.7809 3.96363 13.2087 4.59696 13.5254 5.32474C13.8421 6.05252 14.0004 6.83307 14.0004 7.66641C14.0004 8.49974 13.8421 9.28029 13.5254 10.0081C13.2087 10.7359 12.7809 11.3692 12.2421 11.9081C11.7032 12.447 11.0698 12.8747 10.3421 13.1914C9.61428 13.5081 8.83372 13.6664 8.00039 13.6664ZM9.86706 10.4664L10.8004 9.53307L8.66706 7.39974V4.33307H7.33372V7.93307L9.86706 10.4664ZM3.73372 0.566406L4.66706 1.49974L1.83372 4.33307L0.900391 3.39974L3.73372 0.566406ZM12.2671 0.566406L15.1004 3.39974L14.1671 4.33307L11.3337 1.49974L12.2671 0.566406ZM8.00039 12.3331C9.30039 12.3331 10.4032 11.8803 11.3087 10.9747C12.2143 10.0692 12.6671 8.96641 12.6671 7.66641C12.6671 6.36641 12.2143 5.26363 11.3087 4.35807C10.4032 3.45252 9.30039 2.99974 8.00039 2.99974C6.70039 2.99974 5.59761 3.45252 4.69206 4.35807C3.7865 5.26363 3.33372 6.36641 3.33372 7.66641C3.33372 8.96641 3.7865 10.0692 4.69206 10.9747C5.59761 11.8803 6.70039 12.3331 8.00039 12.3331Z"
                            fill="#1E3354"
                          />
                        </svg>
                      </div>
                      <p className="ml-2 text-[12px]">
                        {formatTime(attendance.punchIn)}
                      </p>
                    </div>
                    <div className="flex items-center mt-1">
                      <div className="w-[18px] flex justify-center">
                        <svg
                          width="12"
                          height="14"
                          viewBox="0 0 12 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.00033 6.9987C6.36699 6.9987 6.68088 6.86814 6.94199 6.60703C7.2031 6.34592 7.33366 6.03203 7.33366 5.66536C7.33366 5.2987 7.2031 4.98481 6.94199 4.7237C6.68088 4.46259 6.36699 4.33203 6.00033 4.33203C5.63366 4.33203 5.31977 4.46259 5.05866 4.7237C4.79755 4.98481 4.66699 5.2987 4.66699 5.66536C4.66699 6.03203 4.79755 6.34592 5.05866 6.60703C5.31977 6.86814 5.63366 6.9987 6.00033 6.9987ZM6.00033 11.8987C7.35588 10.6543 8.36144 9.5237 9.01699 8.50703C9.67255 7.49036 10.0003 6.58759 10.0003 5.7987C10.0003 4.58759 9.61421 3.59592 8.84199 2.8237C8.06977 2.05148 7.12255 1.66536 6.00033 1.66536C4.8781 1.66536 3.93088 2.05148 3.15866 2.8237C2.38644 3.59592 2.00033 4.58759 2.00033 5.7987C2.00033 6.58759 2.3281 7.49036 2.98366 8.50703C3.63921 9.5237 4.64477 10.6543 6.00033 11.8987ZM6.00033 13.6654C4.21144 12.1431 2.87533 10.7293 1.99199 9.4237C1.10866 8.11814 0.666992 6.90981 0.666992 5.7987C0.666992 4.13203 1.2031 2.80425 2.27533 1.81536C3.34755 0.826476 4.58921 0.332031 6.00033 0.332031C7.41144 0.332031 8.6531 0.826476 9.72533 1.81536C10.7975 2.80425 11.3337 4.13203 11.3337 5.7987C11.3337 6.90981 10.892 8.11814 10.0087 9.4237C9.12533 10.7293 7.78921 12.1431 6.00033 13.6654Z"
                            fill="#1E3354"
                          />
                        </svg>
                      </div>
                      <p className="ml-2 text-[12px] ">
                        {attendance.locationIn}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="border-l h-[80px] w-[130px] border-[#787878] ">
                  <div className="ml-2 h-[70px]">
                    <p className="text-[12px] mt-2 ">Clock Out</p>
                    <div className="flex items-center mt-1">
                      <div className="w-[18px] flex justify-center">
                        <svg
                          width="16"
                          height="14"
                          viewBox="0 0 16 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.00039 13.6664C7.16706 13.6664 6.3865 13.5081 5.65872 13.1914C4.93095 12.8747 4.29761 12.447 3.75872 11.9081C3.21984 11.3692 2.79206 10.7359 2.47539 10.0081C2.15872 9.28029 2.00039 8.49974 2.00039 7.66641C2.00039 6.83307 2.15872 6.05252 2.47539 5.32474C2.79206 4.59696 3.21984 3.96363 3.75872 3.42474C4.29761 2.88585 4.93095 2.45807 5.65872 2.14141C6.3865 1.82474 7.16706 1.66641 8.00039 1.66641C8.83372 1.66641 9.61428 1.82474 10.3421 2.14141C11.0698 2.45807 11.7032 2.88585 12.2421 3.42474C12.7809 3.96363 13.2087 4.59696 13.5254 5.32474C13.8421 6.05252 14.0004 6.83307 14.0004 7.66641C14.0004 8.49974 13.8421 9.28029 13.5254 10.0081C13.2087 10.7359 12.7809 11.3692 12.2421 11.9081C11.7032 12.447 11.0698 12.8747 10.3421 13.1914C9.61428 13.5081 8.83372 13.6664 8.00039 13.6664ZM9.86706 10.4664L10.8004 9.53307L8.66706 7.39974V4.33307H7.33372V7.93307L9.86706 10.4664ZM3.73372 0.566406L4.66706 1.49974L1.83372 4.33307L0.900391 3.39974L3.73372 0.566406ZM12.2671 0.566406L15.1004 3.39974L14.1671 4.33307L11.3337 1.49974L12.2671 0.566406ZM8.00039 12.3331C9.30039 12.3331 10.4032 11.8803 11.3087 10.9747C12.2143 10.0692 12.6671 8.96641 12.6671 7.66641C12.6671 6.36641 12.2143 5.26363 11.3087 4.35807C10.4032 3.45252 9.30039 2.99974 8.00039 2.99974C6.70039 2.99974 5.59761 3.45252 4.69206 4.35807C3.7865 5.26363 3.33372 6.36641 3.33372 7.66641C3.33372 8.96641 3.7865 10.0692 4.69206 10.9747C5.59761 11.8803 6.70039 12.3331 8.00039 12.3331Z"
                            fill="#1E3354"
                          />
                        </svg>
                      </div>
                      <p className="ml-2 text-[12px]">
                        {formatTime(attendance.punchOut)}
                      </p>
                    </div>
                    <div className="flex items-center mt-1">
                      <div className="w-[18px] flex justify-center">
                        <svg
                          width="12"
                          height="14"
                          viewBox="0 0 12 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.00033 6.9987C6.36699 6.9987 6.68088 6.86814 6.94199 6.60703C7.2031 6.34592 7.33366 6.03203 7.33366 5.66536C7.33366 5.2987 7.2031 4.98481 6.94199 4.7237C6.68088 4.46259 6.36699 4.33203 6.00033 4.33203C5.63366 4.33203 5.31977 4.46259 5.05866 4.7237C4.79755 4.98481 4.66699 5.2987 4.66699 5.66536C4.66699 6.03203 4.79755 6.34592 5.05866 6.60703C5.31977 6.86814 5.63366 6.9987 6.00033 6.9987ZM6.00033 11.8987C7.35588 10.6543 8.36144 9.5237 9.01699 8.50703C9.67255 7.49036 10.0003 6.58759 10.0003 5.7987C10.0003 4.58759 9.61421 3.59592 8.84199 2.8237C8.06977 2.05148 7.12255 1.66536 6.00033 1.66536C4.8781 1.66536 3.93088 2.05148 3.15866 2.8237C2.38644 3.59592 2.00033 4.58759 2.00033 5.7987C2.00033 6.58759 2.3281 7.49036 2.98366 8.50703C3.63921 9.5237 4.64477 10.6543 6.00033 11.8987ZM6.00033 13.6654C4.21144 12.1431 2.87533 10.7293 1.99199 9.4237C1.10866 8.11814 0.666992 6.90981 0.666992 5.7987C0.666992 4.13203 1.2031 2.80425 2.27533 1.81536C3.34755 0.826476 4.58921 0.332031 6.00033 0.332031C7.41144 0.332031 8.6531 0.826476 9.72533 1.81536C10.7975 2.80425 11.3337 4.13203 11.3337 5.7987C11.3337 6.90981 10.892 8.11814 10.0087 9.4237C9.12533 10.7293 7.78921 12.1431 6.00033 13.6654Z"
                            fill="#1E3354"
                          />
                        </svg>
                      </div>
                      <p className="ml-2 text-[12px] ">
                        {attendance.locationOut}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        
      </div>
    </>
  );

}

export default SmartAttendance;
