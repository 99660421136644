import { Link, useLocation } from "react-router-dom";
import Logo from "../images/logo/logo.png";
import DropdownNotification from "./DropdownNotification";
import DropdownUser from "./DropdownUser";
import { useEffect, useState } from "react";
import HeaderHome from "./Headerhome";
import HeaderAllPage from "./HeaderAllPage";
import CeoInbox from "./CeoInbox";
import { ThemeSelector } from "../components/Theme/Themecontext";

const Header = ({ setHeaderOpen }) => {
  const location = useLocation();

  const isDashboard = location.pathname === "/home";
  setTimeout(() => {
    setIsRefresh(false);
  }, 5000);

  const [isRefresh, setIsRefresh] = useState(false);
  const handleRefresh = () => {
    setIsRefresh(true);
  };
  return (
    <>
      {/* desktop */}
      <header className=" hidden lg:block relative rounded-b-[20px] lg:rounded-[0px] top-0   w-full border-b-2  drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none">
        <div className="flex h-[90px] flex-grow items-center justify-between py-4 px-4 md:px-6 2xl:px-11">
          <div className="lg:flex hidden ">
            <CeoInbox isRefresh={isRefresh} />
          </div>
          {/* <div>
            <ThemeSelector/>
          </div> */}

          <div className="flex items-center gap-2 sm:gap-4 lg:hidden"></div>

          <div className="flex w-[200px] items-center gap-3 2xsm:gap-7">
            <div className="absolute left-3 lg:relative">
              <DropdownUser />
            </div>
          </div>
        </div>
      </header>
      {/* mobile header  */}

      {isDashboard && <HeaderHome setHeaderOpen={setHeaderOpen} />}

      {!isDashboard && <HeaderAllPage currentPath={location.pathname} />}
    </>
  );
};

export default Header;
